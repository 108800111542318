import React from 'react'
import Navbar2 from './Navbar2'
import Footer2 from './Footer2' 
const About = () => {
  return (
    <>
     <Navbar2/>

      {/*        breadcrumb_area     */}
      <section
        className="breadcrumb_area parallaxie"
        data-background="assets/img/bg.jpg"
        style={{ background: "url(assets/img/breadcrumb_bg_one.jpg) no-repeat" }}
      >
        <div className="overlay" />
        <div className="container custom_container">
          <div className="breadcrumb_content">
            <h1>About Us</h1>
            <p>We love Design, Technology &amp; Story.</p>
          </div>
        </div>
      </section>
      {/*        breadcrumb_area     */}
      {/*        video_area     */}
      <section className="video_area sec_pad">
        <div className="container custom_container">
          <div className="sec_title text-center">
            <h2 className="s_section_title">Make Digital Ideas</h2>
            <p>We love design, technology, story &amp; design beautiful brands</p>
          </div>
          <div className="sc_video_inner">
            <img
              className="p_absoulte video_bg"
              src="assets/img/home_three/video_bg.png"
              alt=""
            />
            <img className="dot_l p_absoulte" src="img/v_dot.png" alt="" />
            <img className="dot_r p_absoulte" src="img/v_dot_1.png" alt="" />
            <a
              href="https://www.youtube.com/watch?v=sU3FkzUKHXU"
              className="v_icon popup_youtube"
            >
              <span className="dot_animation" />
              <img src="assets/img/about/video_icon2.png" alt="" />
            </a>
          </div>
        </div>
      </section>
      {/*        video_area     */}
      {/*        process_area     */}
      <section className="process_area sec_pad">
        <img className="dot_one p_absoulte" src="img/home_three/dot.png" alt="" />
        <img className="dot_two p_absoulte" src="img/home_three/dot-1.png" alt="" />
        <div className="container custom_container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="process_item">
                <img src="assets/img/p_icon.png" alt="" />
                {/* <h4 data-text={01}>Project Study</h4> */}
                <p>
                  Strategically design beautiful brands, websites and digitals on
                  products actually.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="process_item">
                <img src="assets/img/p_icon_2.png" alt="" />
                {/* <h4 data-text={02}>Research &amp; Idea</h4> */}
                <p>
                  Strategically design beautiful brands, websites and digitals on
                  products actually.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="process_item">
                <img src="assets/img/p_icon_3.png" alt="" />
                {/* <h4 data-text={03}>Development</h4> */}
                <p>
                  Strategically design beautiful brands, websites and digitals on
                  products actually.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="process_item">
                <img src="assets/img/p_icon_4.png" alt="" />
                {/* <h4 data-text={04}>Testing Project</h4> */}
                <p>
                  Strategically design beautiful brands, websites and digitals on
                  products actually.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*        process_area     */}
      {/*        team_area     */}
      <section className="team_area sec_pad">
        <div className="container custom_container">
          <div className="sec_title text-center">
            <h2 className="s_section_title">Make Digital Ideas</h2>
            <p>We love design, technology, story &amp; design beautiful brands</p>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="team_item">
                <div className="team-img">
                  <img src="assets/img/about/team1.jpg" alt="" />
                  <ul className="list-unstyled social_link">
                    <li>
                      <a href="#">
                        <i className="social_dribbble" />
                        <i className="social_dribbble" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_googleplus" />
                        <i className="social_googleplus" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_facebook" />
                        <i className="social_facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_twitter" />
                        <i className="social_twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
                <h4>
                  Jhon Doe <span>/ Designer</span>
                </h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="team_item">
                <div className="team-img">
                  <img src="assets/img/about/team2.jpg" alt="" />
                  <ul className="list-unstyled social_link">
                    <li>
                      <a href="#">
                        <i className="social_dribbble" />
                        <i className="social_dribbble" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_googleplus" />
                        <i className="social_googleplus" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_facebook" />
                        <i className="social_facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_twitter" />
                        <i className="social_twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
                <h4>
                  Jhon Doe <span>/ Designer</span>
                </h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="team_item">
                <div className="team-img">
                  <img src="assets/img/about/team3.jpg" alt="" />
                  <ul className="list-unstyled social_link">
                    <li>
                      <a href="#">
                        <i className="social_dribbble" />
                        <i className="social_dribbble" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_googleplus" />
                        <i className="social_googleplus" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_facebook" />
                        <i className="social_facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_twitter" />
                        <i className="social_twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
                <h4>
                  Jhon Doe <span>/ Designer</span>
                </h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="team_item">
                <div className="team-img">
                  <img src="assets/img/about/team4.jpg" alt="" />
                  <ul className="list-unstyled social_link">
                    <li>
                      <a href="#">
                        <i className="social_dribbble" />
                        <i className="social_dribbble" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_googleplus" />
                        <i className="social_googleplus" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_facebook" />
                        <i className="social_facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_twitter" />
                        <i className="social_twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
                <h4>
                  Jhon Doe <span>/ Designer</span>
                </h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="team_item">
                <div className="team-img">
                  <img src="assets/img/about/team5.jpg" alt="" />
                  <ul className="list-unstyled social_link">
                    <li>
                      <a href="#">
                        <i className="social_dribbble" />
                        <i className="social_dribbble" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_googleplus" />
                        <i className="social_googleplus" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_facebook" />
                        <i className="social_facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_twitter" />
                        <i className="social_twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
                <h4>
                  Jhon Doe <span>/ Designer</span>
                </h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="team_item">
                <div className="team-img">
                  <img src="assets/img/about/team6.jpg" alt="" />
                  <ul className="list-unstyled social_link">
                    <li>
                      <a href="#">
                        <i className="social_dribbble" />
                        <i className="social_dribbble" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_googleplus" />
                        <i className="social_googleplus" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_facebook" />
                        <i className="social_facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="social_twitter" />
                        <i className="social_twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
                <h4>
                  Jhon Doe <span>/ Designer</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*        team_area     */}
      {/*        testimonial_area     */}
      <section className="testimonial_area">
        <div className="container custom_container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="clients_inner">
                <img className="quote_img" src="assets/img/about/quote2.png" alt="" />
                <h2 className="s_section_title">Clients Love</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiu
                  smod tempor incididunt ut labore et dolore magnan aliqua. Quis
                  ipsum suspendisse ultrices gravida.{" "}
                </p>
                <div className="ab_clients_logo">
                  <div className="ab_clients_logo_item">
                    <a href="#">
                      <img src="assets/img/about/clients_1.png" alt="" />
                    </a>
                  </div>
                  <div className="ab_clients_logo_item">
                    <a href="#">
                      <img src="assets/img/about/clients_2.png" alt="" />
                    </a>
                  </div>
                  <div className="ab_clients_logo_item">
                    <a href="#">
                      <img src="assets/img/about/clients_3.png" alt="" />
                    </a>
                  </div>
                </div>
                <a href="#" className="p_btn p_btn_w">
                  Contact Us
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="testimonial_slider">
                <div className="item">
                  <div className="media">
                    <div className="img">
                      <img src="assets/img/about/testimonial_1.png" alt="" />
                    </div>
                    <div className="media-body">
                      <img
                        className="quote_img p_absoulte"
                        src="assets/img/about/quote.png"
                        alt=""
                      />
                      <h5>Zuttow Rold</h5>
                      <p>
                        We strategically design beautiful brands and websites, and
                        digital products
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="media">
                    <div className="img">
                      <img src="assets/img/about/testimonial_1.png" alt="" />
                    </div>
                    <div className="media-body">
                      <img
                        className="quote_img p_absoulte"
                        src="assets/img/about/quote.png"
                        alt=""
                      />
                      <h5>Zuttow Rold</h5>
                      <p>
                        We strategically design beautiful brands and websites, and
                        digital products
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="media">
                    <div className="img">
                      <img src="assets/img/about/testimonial_1.png" alt="" />
                    </div>
                    <div className="media-body">
                      <img
                        className="quote_img p_absoulte"
                        src="assets/img/about/quote.png"
                        alt=""
                      />
                      <h5>Zuttow Rold</h5>
                      <p>
                        We strategically design beautiful brands and websites, and
                        digital products
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="media">
                    <div className="img">
                      <img src="assets/img/about/testimonial_1.png" alt="" />
                    </div>
                    <div className="media-body">
                      <img
                        className="quote_img p_absoulte"
                        src="assets/img/about/quote.png"
                        alt=""
                      />
                      <h5>Zuttow Rold</h5>
                      <p>
                        We strategically design beautiful brands and websites, and
                        digital products
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*        testimonial_area     */}
      {/*        clients_logo_area     */}
      <section className="clients_logo_area sec_pad">
        <div className="container custom_container">
          <div className="sc_clients_logo_info">
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_1.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_2.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_3.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_4.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_5.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_6.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_7.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_8.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_9.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_10.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_11.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_12.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_13.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_14.png" alt="" />
            </a>
            <a href="#" className="c_logo_item">
              <img src="assets/img/home_three/clients-log/logo_15.png" alt="" />
            </a>
          </div>
        </div>
      </section>
      {/*        clients_logo_area     */}
  
      <Footer2/>
    </>

  )
}

export default About