import React from 'react'

function Footer2() {
  return (
    <>
     {/*        footer_area     */}
     <footer className="footer_area">
        <div className="container custom_container">
          <div className="footer_top">
            <a href="#" className="f_logo">
              <img
                src="assets\img\images\Untitled-design-3.png"
                srcSet="assets/img/wlogo@2x.png 2x"
                alt=""
              />
            </a>
            <h3>
              Have a project in mind? <a href="#">Lets Discuss</a>
            </h3>
            <ul className="list-unstyled social_link">
              <li>
                <a href="#">
                  <i className="social_dribbble" />
                  <i className="social_dribbble" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="social_googleplus" />
                  <i className="social_googleplus" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="social_facebook" />
                  <i className="social_facebook" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="social_twitter" />
                  <i className="social_twitter" />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer_bottom">
            <p>
              Copyright © 2020 <a href="#">DroitThemes</a> | All rights reserved
            </p>
          </div>
        </div>
      </footer>
      {/*        footer_area     */}

    </>
  )
}

export default Footer2