import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from './Navbar';
import Footer from './Footer';
// import Slider from './Slider'

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500
    });
  }, []);

  return (
    <>
      <Navbar />
      <div id="wavescroll">
        {/* Section One */}
        <section className="section wave_one_section_one">
          <div className="t_first p_absoulte">
            <img
              className="layer layer2"
              data-depth="-0.20"
              src="assets/img/home_one/triangle_shap_one.png"
              alt=""
            />
          </div>
          <div className="t_two p_absoulte">
            <img
              className="layer layer2"
              data-depth="0.30"
              src="assets/img/home_one/triangle_shap_two.png"
              alt=""
            />
          </div>
          <div className="intro">
            <div className="container custom_container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="w_content">
                    <img
                      className="circle_line"
                      src="assets/img/home_one/circle_line.png"
                      alt=""
                    />
                    <img
                      className="t_three p_absoulte wow"
                      src="assets/img/home_one/circle_blue.png"
                      alt=""
                    />
                    <h2 data-aos="zoom-in">Creative Agency</h2>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="w_img_one">
                    <img src="assets/img/home_one/first_slide.png" alt="" data-aos="zoom-in" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Section Two */}
        <section className="section wave_one_section_two">
        <div className=" p_absoulte" data-aos="zoom-in">
            <img
              className="layer layer2"
              data-depth="-0.20"
              src="assets/img/home_one/triangle_shap_one.png"
              alt=""
            />
          </div>
          <div className="t_two p_absoulte">
            <img
              className="layer layer2"
              data-depth="0.50"
              src="assets/img/home_one/triangle_shap_two.png"
              alt=""
            />
          </div>
          <div className="t_four p_absoulte">
            <img
              className="layer layer2"
              data-depth="0.30"
              src="assets/img/home_one/triangle_shap_three.png"
              alt=""
            />
          </div>
          <div className="t_five p_absoulte">
            <img
              className="layer layer2"
              data-depth="0.10"
              src="assets/img/home_one/triangle_shap_four.png"
              alt=""
            />
          </div>
          <div className="s_round r_one p_absoulte" />
          <div className="s_round r_two p_absoulte" />
          <div className="s_round r_three p_absoulte" />
          <div className="intro">
            <div className="container custom_container">
              <div className="row align-items-center" style={{ paddingTop: "9rem" }}>
                <div className="col-lg-6" data-aos="fade-right">
                  <div className="w_content_two">
                    <h2>Wise Stone</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Quis ipsum suspendisse ultrices gravida.
                    </p>
                    <a href="#" className="p_btn">
                      Case Study
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="">
                    <div className="round p_absoulte" />
                    <img src="assets/img/home_one/laptop_w.png" className='img-fluid' data-aos="fade-left" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       
        {/* Section Three */}
        <section className="section wave_one_section_three">
          <div className="t_first p_absoulte">
            <img
              className="layer layer2"
              data-depth="-0.20"
              src="assets/img/home_one/t_angle.png"
              alt=""
            />
          </div>
          <div className="t_two p_absoulte">
            <img
              className="layer layer2"
              data-depth="0.50"
              src="assets/img/home_one/triangle_shap_two.png"
              alt=""
            />
          </div>
          <div className="t_four p_absoulte">
            <img
              className="layer layer2"
              data-depth="0.30"
              src="assets/img/home_one/triangle_shap_three.png"
              alt=""
            />
          </div>
          <div className="t_five p_absoulte">
            <img
              className="layer layer2"
              data-depth="0.10"
              src="assets/img/home_one/triangle_shap_four.png"
              alt=""
            />
          </div>
          <div className="intro">
            <div className="container custom_container">
              <div className="row align-items-center" style={{ paddingTop: "9rem" }}>
                <div className="col-lg-6" data-aos="fade-left">
                  <div className="w_content_two">
                    <h2>Wise Stone</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Quis ipsum suspendisse ultrices gravida.
                    </p>
                    <a href="#" className="p_btn">
                      Case Study
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="w_phone_img">
                    <img
                      className="p_absoulte dot"
                      src="assets/img/home_one/dot2.png"
                      alt=""
                    />
                    <img className="p_one" src="assets/img/home_one/iphone.png" data-aos="fade-right" alt="" />
                    <img
                      className="p_two p_absoulte"
                      src="assets/img/home_one/iphone1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <Slider/> */}
        {/* Section Four */}
        <section className="section wave_one_section_four" style={{ paddingTop: "9rem" }}>
          <div className="t_first p_absoulte">
            <img
              className="layer layer2"
              data-depth="-0.20"
              src="assets/img/home_one/t_angle.png"
              alt=""
            />
          </div>
          <div className="t_two p_absoulte">
            <img
              className="layer layer2"
              data-depth="0.50"
              src="assets/img/home_one/triangle_shap_two.png"
              alt=""
            />
          </div>
          <div className="t_four p_absoulte">
            <img
              className="layer layer2"
              data-depth="0.30"
              src="assets/img/home_one/triangle_shap_three.png"
              alt=""
            />
          </div>
          <div className="t_five p_absoulte">
            <img
              className="layer layer2"
              data-depth="0.10"
              src="assets/img/home_one/triangle_shap_five.png"
              alt=""
            />
          </div>
          <div className="intro">
            <div className="container custom_container">
              <div className="row align-items-center">
                <div className="col-lg-6" data-aos="zoom-out">
                  <div className="w_content_two">
                    <h2>Secud Dee</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Quis ipsum suspendisse ultrices gravida.
                    </p>
                    <a href="#" className="p_btn">
                      Case Study
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="w_mockup_img">
                    <div className="img_screen one" >
                      <img src="assets/img/home_one/screen_three.jpg" data-aos="zoom-out" data-aos-duration="500" alt="" />
                    </div>
                    <div className="img_screen two">
                      <img src="assets/img/home_one/screen_two.jpg" data-aos="zoom-out" data-aos-duration="1000" alt="" />
                    </div>
                    <div className="img_screen three">
                      <img src="assets/img/home_one/screen_one.jpg" data-aos="zoom-out" data-aos-duration="1500" alt="" />
                    </div>
                    <img className="img_four" src="assets/img/home_one/code.jpg" data-aos="zoom-out" data-aos-duration="2000" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Home;
