import React from "react";

const Navbar = () => {
  return (
    <>
      {/*    header   */}
      <header className="header_area_one p_absoulte m_p">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-9 col-7">
              <div className="menu_left">
                <a href="#" className="logo">
                  <img
                    src="assets\img\images\Untitled-design-3.png"
                    srcSet="img/wlogo@2x.png 2x"
                    alt=""
                  />
                </a>
                <div className="h_contact_info">
                  <a href="#">+0074 215 2455</a>
                  <a href="#">contact@wave.com</a>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-5">
              <div className="menu_right">
                <div className="burger_menu">
                  <span className="text" data-text="menu" />
                  <div className="dot_icon">
                    <span className="dot one" />
                    <span className="dot two" />
                    <span className="dot three" />
                    <span className="dot four" />
                    <span className="dot five" />
                    <span className="dot six" />
                    <span className="dot seven" />
                    <span className="dot eight" />
                    <span className="dot nine" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/*    header   */}
      {/*    hamburger_menu   */}
      <div className="hamburger_menu_wrepper" id="menu">
        <div className="animation-box">
          <div className="top_menu">
            <a href="index.html">
              <img src="img/logo.png" srcSet="img/wlogo@2x.png 2x" alt="logo" />
            </a>
            <div className="burger_menu close_icon">
              <span className="text" data-text="Close" />
              <i className="icon_close" />
            </div>
          </div>
          <div className="menu-box navbar">
            <ul className="navbar-nav justify-content-end menu offcanfas_menu">
              <li className="nav-item dropdown submenu active">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Home
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item active">
                    <a href="index.html" className="nav-link">
                      Design Studio
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="index-2.html" className="nav-link">
                      Full Sereen Portfolio
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="index-3.html" className="nav-link">
                      Creative Agency
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="index-4.html" className="nav-link">
                      Home Split
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="index-5.html" className="nav-link">
                      Creative Carousel
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="fullscreen-slider.html" className="nav-link">
                      Fullscreen Carousel
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="horizontal-carousel.html" className="nav-link">
                      Horizontal Carousel
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="masonry-portfolio.html" className="nav-link">
                      Masonry Portfolio
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown submenu">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Pages
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <a href="about.html" className="nav-link">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="contact.html" className="nav-link">
                      Contact
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown submenu">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Portfolio
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <a href="portfolio-one.html" className="nav-link">
                      Portfolio One
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="portfolio-two.html" className="nav-link">
                      Portfolio Two
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="portfolio-fluid-one.html" className="nav-link">
                      Portfolio Fluid One
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="portfolio-fluid-two.html" className="nav-link">
                      Portfolio Fluid Two
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="portfolio-masonary-one.html" className="nav-link">
                      Portfolio Masonary
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="portfolio-details-one.html" className="nav-link">
                      Portfolio Details One
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="portfolio-details-two.html" className="nav-link">
                      Portfolio Details Two
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="portfolio-details-three.html" className="nav-link">
                      Portfolio Details Three
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown submenu">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Blog
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <a href="blog-list.html" className="nav-link">
                      Blog List
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="blog-details-one.html" className="nav-link">
                      Blog Details One
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="blog-details-two.html" className="nav-link">
                      Blog Details Two
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown submenu">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Shop
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <a href="shop.html" className="nav-link">
                      Shop Sidebar
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="shop-list.html" className="nav-link">
                      Shop List
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="product-details.html" className="nav-link">
                      Product Details
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="cart.html" className="nav-link">
                      Shoping Cart
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="checkout.html" className="nav-link">
                      Checkout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/*    hamburger_menu   */}
    </>
  );
};

export default Navbar;
