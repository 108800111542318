import React from 'react'

function Navbar2() {
  return (
    <>
    {/*        header     */}
    <header className="header_area p_absoulte m_p sticky_nav">
    <nav className="navbar navbar-expand-lg" id="header">
      <div className="container-fluid">
        <div className="logo_info">
          <a href="#" className="navbar-brand logo_two">
            <img src="assets\img\images\Untitled-design-3.png" srcSet="img/wlogo@2x.png 2x" alt="" />
            <img
              src="img/dark_logo.png"
              srcSet="img/dark_logo2x.png 2x"
              alt=""
            />
          </a>
          <div className="h_contact_info">
            <a href="#">+0074 215 2455</a>
            <a href="#">contact@wave.com</a>
          </div>
        </div>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="menu_toggle">
            <span className="hamburger">
              <span />
              <span />
              <span />
            </span>
            <span className="hamburger-cross">
              <span />
              <span />
            </span>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto menu">
            <li className="nav-item ">
            <a href="/" className="nav-link">
              Home
            </a>
              {/* <ul className="dropdown-menu">
                <li className="nav-item">
                  <a href="index.html" className="nav-link">
                    Design Studio
                  </a>
                </li>
                <li className="nav-item">
                  <a href="index-2.html" className="nav-link">
                    Full Sereen Portfolio
                  </a>
                </li>
                <li className="nav-item">
                  <a href="index-3.html" className="nav-link">
                    Creative Agency
                  </a>
                </li>
                <li className="nav-item">
                  <a href="index-4.html" className="nav-link">
                    Home Split
                  </a>
                </li>
                <li className="nav-item">
                  <a href="index-5.html" className="nav-link">
                    Creative Carousel
                  </a>
                </li>
                <li className="nav-item">
                  <a href="fullscreen-slider.html" className="nav-link">
                    Fullscreen Carousel
                  </a>
                </li>
                <li className="nav-item">
                  <a href="horizontal-carousel.html" className="nav-link">
                    Horizontal Carousel
                  </a>
                </li>
                <li className="nav-item">
                  <a href="masonry-portfolio.html" className="nav-link">
                    Masonry Portfolio
                  </a>
                </li>
              </ul> */}
            </li>
            <li className="nav-item ">
            <a href="about" className="nav-link">
              About
            </a>
            </li>
            <li className="nav-item">
              <a href="services" className="nav-link">
                Services
              </a>
            </li>
            <li className="nav-item">
              <a href="/blog" className="nav-link">
                Blog 
              </a>
            </li>
            <li className="nav-item ">
              <a href="contactUs" className="nav-link">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="burger_menu side_menu">
          <div className="dot_icon">
            <span className="dot one" />
            <span className="dot two" />
            <span className="dot three" />
            <span className="dot four" />
            <span className="dot five" />
            <span className="dot six" />
            <span className="dot seven" />
            <span className="dot eight" />
            <span className="dot nine" />
          </div>
        </div>
      </div>
    </nav>
    </header>
    {/*        header     */}
    </>
  )
}

export default Navbar2