import React from 'react'
import Navbar2 from './Navbar2'
import Footer2 from './Footer2'

function Blog() {
  return (
    <>
    <Navbar2/>
  {/*       breadcrumb_area_two     */}
  <section className="breadcrumb_area_two parallaxie" data-background="assets/img/bg.jpg"
    style={{ background: "url(assets/img/blog_banner.jpg) no-repeat" }}>
    <div className="overlay" />
    <div className="container">
      <div className="breadcrumb_content">
        <h1>Blog Listing</h1>
        <ol className="nav">
          <li>
            <a href="#">Home</a>
          </li>
          <li>Blog</li>
        </ol>
      </div>
    </div>
  </section>
  {/*       breadcrumb_area_two     */}
  {/*       blog_area     */}
  <section className="blog_area sec_pad">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <div className="blog_inner">
            <div className="blog_list_item">
              <img src="assets/img/blog/blog-list1.jpg" alt="" />
              <div className="b_inner">
                <div className="blog_list_content">
                  <div className="b_post_info">
                    <div className="p_tag">
                      <a href="#">Design</a>
                      <a href="#">Marketing</a>
                    </div>
                    <div className="p_date">
                      <img src="assets/img/blog/date_icon.png" alt="" />
                      July 24, 2019
                    </div>
                  </div>
                  <a href="#">
                    <h2>
                      Tip for Promotng Your Business and Creating Your Brand
                    </h2>
                  </a>
                  <div className="d-flex justify-content-between">
                    <a href="#" className="read_btn">
                      Read More <i className="arrow_triangle-right" />
                    </a>
                    <a href="#" className="comment_btn">
                      <img src="assets/img/blog/comment_icon.png" alt="" />
                      Comments (5)
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="blog_list_item">
              <div className="video_img">
                <img src="assets/img/blog/video_post.png" alt="" />
                <a href="#" className="v_icon">
                  <span className="dot_animation" />
                  <i className="arrow_triangle-right" />
                </a>
              </div>
              <div className="b_inner">
                <div className="blog_list_content">
                  <div className="b_post_info">
                    <div className="p_tag">
                      <a href="#">Design</a>
                      <a href="#">Marketing</a>
                    </div>
                    <div className="p_date">
                      <img src="assets/img/blog/date_icon.png" alt="" />
                      July 24, 2019
                    </div>
                  </div>
                  <a href="#">
                    <h2>
                      Designing For Smartphone Tips and Tricks For Business
                    </h2>
                  </a>
                  <div className="d-flex justify-content-between">
                    <a href="#" className="read_btn">
                      Read More <i className="arrow_triangle-right" />
                    </a>
                    <a href="#" className="comment_btn">
                      <img src="assets/img/blog/comment_icon.png" alt="" />
                      Comments (5)
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <blockquote>
              <p>
                Social media change the relationship between them also companie
                and cust omers from masters to servants and many more to come in
                the end of the season.
              </p>
              <div className="author">Rodney Artichoke</div>
            </blockquote>
            <div className="blog_audio_item">
              <audio
                id="player2"
                className="mejs__player"
                preload="none"
                controls=""
              >
                <source
                  src="http://www.largesound.com/ashborytour/sound/AshboryBYU.mp3"
                  type="audio/mp3"
                />
              </audio>
              <div className="b_inner">
                <div className="blog_list_content">
                  <div className="b_post_info">
                    <div className="p_tag">
                      <a href="#">Design</a>
                      <a href="#" className="green">
                        Marketing
                      </a>
                    </div>
                    <div className="p_date">
                      <img src="assets/img/blog/date_icon.png" alt="" />
                      July 24, 2019
                    </div>
                  </div>
                  <a href="#">
                    <h2>
                      Tip for Promotng Your Business and Creating Your Brand
                    </h2>
                  </a>
                  <div className="d-flex justify-content-between">
                    <a href="#" className="read_btn">
                      Read More <i className="arrow_triangle-right" />
                    </a>
                    <a href="#" className="comment_btn">
                      <img src="img/blog/comment_icon.png" alt="" />
                      Comments (5)
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="blog_list_item">
              <img src="assets/img/blog/blog-list2.jpg" alt="" />
              <div className="b_inner">
                <div className="blog_list_content">
                  <div className="b_post_info">
                    <div className="p_tag">
                      <a href="#">Design</a>
                      <a href="#" className="yellow">
                        Marketing
                      </a>
                    </div>
                    <div className="p_date">
                      <img src="assets/img/blog/date_icon.png" alt="" />
                      July 24, 2019
                    </div>
                  </div>
                  <a href="#">
                    <h2>
                      Tip for Promotng Your Business and Creating Your Brand
                    </h2>
                  </a>
                  <div className="d-flex justify-content-between">
                    <a href="#" className="read_btn">
                      Read More <i className="arrow_triangle-right" />
                    </a>
                    <a href="#" className="comment_btn">
                      <img src="assets/img/blog/comment_icon.png" alt="" />
                      Comments (5)
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <blockquote className="blockquote_two">
              <p>
                Aron Klein’s captivating images of the Bulgarian demon chasers.
                Tinkety tonk old fruit old blower a load of old tosh burke and
                their team
              </p>
            </blockquote>
            <nav
              className="navigation pagination justify-content-center"
              role="navigation"
            >
              <div className="nav-links">
                <a className="previous page-numbers" href="#">
                  Previous
                </a>
                <span aria-current="page" className="page-numbers current">
                  1
                </span>
                <a className="page-numbers" href="#">
                  2
                </a>
                <a className="page-numbers" href="#">
                  3
                </a>
                <a className="page-numbers" href="#">
                  4
                </a>
                <a className="page-numbers" href="#">
                  5
                </a>
                <a className="next page-numbers" href="#">
                  Next
                </a>
              </div>
            </nav>
          </div>
        </div>
        <div className="col-lg-4 col-md-12">
          <div className="blog_sidebar">
            <div className="widget widget_search">
              <form action="#" className="search-form input-group">
                <input
                  type="search"
                  className="form-control widget_input"
                  placeholder="Search"
                />
                <button type="submit">
                  <i className="icon_search" />
                </button>
              </form>
            </div>
            <div className="widget widget_categorie">
              <h3 className="sidebar_title">Categories</h3>
              <ul className="list-unstyled">
                <li>
                  <a href="#">
                    <i className="arrow_triangle-right" />
                    Fashion (15)
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="arrow_triangle-right" />
                    Management (06)
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="arrow_triangle-right" />
                    Gaming (08)
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="arrow_triangle-right" />
                    Uncategorized (12)
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="arrow_triangle-right" />
                    Saas &amp; app (14)
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="arrow_triangle-right" />
                    Wireframing (07)
                  </a>
                </li>
              </ul>
            </div>
            <div className="widget widget_post">
              <h3 className="sidebar_title">Latest News</h3>
              <div className="media post_item">
                <img src="assets/img/blog/l_news_one.jpg" alt="" />
                <div className="media-body">
                  <a href="#">
                    <h5>The harder you work them more luck.</h5>
                  </a>
                  <div className="p_date">
                    <img src="img/blog/date_icon.png" alt="" />
                    July 24, 2019
                  </div>
                </div>
              </div>
              <div className="media post_item">
                <img src="assets/img/blog/l_news_two.jpg" alt="" />
                <div className="media-body">
                  <a href="#">
                    <h5>Make life by what you an give in future</h5>
                  </a>
                  <div className="p_date">
                    <img src="assets/img/blog/date_icon.png" alt="" />
                    July 24, 2019
                  </div>
                </div>
              </div>
              <div className="media post_item">
                <img src="assets/img/blog/l_news_three.jpg" alt="" />
                <div className="media-body">
                  <a href="#">
                    <h5>People all forward and on success.</h5>
                  </a>
                  <div className="p_date">
                    <img src="assets/img/blog/date_icon.png" alt="" />
                    July 24, 2019
                  </div>
                </div>
              </div>
            </div>
            <div className="widget widget_comments">
              <h3 className="sidebar_title">Recent Comments</h3>
              <div className="media comments_item">
                <img
                  className="rounded-circle"
                  src="assets/img/blog/comments_img.jpg"
                  alt=""
                />
                <div className="media-body">
                  <p>
                    <a href="#">Piff Jenkins</a>
                    on
                    <br /> Tinkety tonk old fruit blower and load of old tosh
                    burke skive!
                  </p>
                </div>
              </div>
              <div className="media comments_item">
                <img
                  className="rounded-circle"
                  src="assets/img/blog/comments_2.jpg"
                  alt=""
                />
                <div className="media-body">
                  <p>
                    <a href="#">Piff Jenkins</a>
                    on
                    <br /> Tinkety tonk old fruit blower and load of old tosh
                    burke skive!
                  </p>
                </div>
              </div>
              <div className="media comments_item">
                <img
                  className="rounded-circle"
                  src="assets/img/blog/comments_3.jpg"
                  alt=""
                />
                <div className="media-body">
                  <p>
                    <a href="#">Piff Jenkins</a>
                    on
                    <br /> Tinkety tonk old fruit blower and load of old tosh
                    burke skive!
                  </p>
                </div>
              </div>
            </div>
            <div className="widget widget_instragram">
              <h3 className="sidebar_title">Instagram Feed</h3>
              <ul className="list-unstyled">
                <li>
                  <a href="#">
                    <img src="assets/img/blog/instragram_1.jpg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="assets/img/blog/instragram_2.jpg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="assets/img/blog/instragram_3.jpg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="assets/img/blog/instragram_4.jpg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="assets/img/blog/instragram_5.jpg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="assets/img/blog/instragram_6.jpg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="assets/img/blog/instragram_7.jpg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="assets/img/blog/instragram_8.jpg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="assets/img/blog/instragram_9.jpg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="widget widget_tag">
              <h3 className="sidebar_title">Popular Tags</h3>
              <ul className="list-unstyled">
                <li>
                  <a href="#" className="tag">
                    App &amp; Saas
                  </a>
                </li>
                <li>
                  <a href="#" className="tag">
                    UI design
                  </a>
                </li>
                <li>
                  <a href="#" className="tag">
                    Saas
                  </a>
                </li>
                <li>
                  <a href="#" className="tag">
                    Cooling System
                  </a>
                </li>
                <li>
                  <a href="#" className="tag">
                    Business
                  </a>
                </li>
                <li>
                  <a href="#" className="tag">
                    Finance
                  </a>
                </li>
                <li>
                  <a href="#" className="tag">
                    App &amp; Saas
                  </a>
                </li>
                <li>
                  <a href="#" className="tag">
                    Saas
                  </a>
                </li>
                <li>
                  <a href="#" className="tag">
                    Software
                  </a>
                </li>
                <li>
                  <a href="#" className="tag">
                    Landing Page
                  </a>
                </li>
                <li>
                  <a href="#" className="tag">
                    Software
                  </a>
                </li>
              </ul>
            </div>
            <div className="widget widget_categorie">
              <h3 className="sidebar_title">Archive Posts</h3>
              <ul className="list-unstyled navbar-nav">
                <li className="archive_nav_item active">
                  <a href="#">
                    <i className="arrow_triangle-right" />
                    January (25)
                  </a>
                  <ul className="list-unstyled dropdown-menu">
                    <li>
                      <a href="#">Marketing (05)</a>
                    </li>
                    <li>
                      <a href="#">Design (05)</a>
                    </li>
                    <li>
                      <a href="#">Branding (05)</a>
                    </li>
                    <li>
                      <a href="#">Playing (05)</a>
                    </li>
                    <li>
                      <a href="#">Browsing (05)</a>
                    </li>
                  </ul>
                </li>
                <li className="archive_nav_item">
                  <a href="#">
                    <i className="arrow_triangle-right" />
                    Fabruary (06)
                  </a>
                  <ul className="list-unstyled dropdown-menu">
                    <li>
                      <a href="#">Marketing (05)</a>
                    </li>
                    <li>
                      <a href="#">Design (05)</a>
                    </li>
                    <li>
                      <a href="#">Branding (05)</a>
                    </li>
                    <li>
                      <a href="#">Playing (05)</a>
                    </li>
                    <li>
                      <a href="#">Browsing (05)</a>
                    </li>
                  </ul>
                </li>
                <li className="archive_nav_item">
                  <a href="#">
                    <i className="arrow_triangle-right" />
                    March (08)
                  </a>
                  <ul className="list-unstyled dropdown-menu">
                    <li>
                      <a href="#">Marketing (05)</a>
                    </li>
                    <li>
                      <a href="#">Design (05)</a>
                    </li>
                    <li>
                      <a href="#">Branding (05)</a>
                    </li>
                    <li>
                      <a href="#">Playing (05)</a>
                    </li>
                    <li>
                      <a href="#">Browsing (05)</a>
                    </li>
                  </ul>
                </li>
                <li className="archive_nav_item">
                  <a href="#">
                    <i className="arrow_triangle-right" />
                    April (12)
                  </a>
                  <ul className="list-unstyled dropdown-menu">
                    <li>
                      <a href="#">Marketing (05)</a>
                    </li>
                    <li>
                      <a href="#">Design (05)</a>
                    </li>
                    <li>
                      <a href="#">Branding (05)</a>
                    </li>
                    <li>
                      <a href="#">Playing (05)</a>
                    </li>
                    <li>
                      <a href="#">Browsing (05)</a>
                    </li>
                  </ul>
                </li>
                <li className="archive_nav_item">
                  <a href="#">
                    <i className="arrow_triangle-right" />
                    May (14)
                  </a>
                </li>
                <li className="archive_nav_item">
                  <a href="#">
                    <i className="arrow_triangle-right" />
                    june (14)
                  </a>
                  <ul className="list-unstyled dropdown-menu">
                    <li>
                      <a href="#">Marketing (05)</a>
                    </li>
                    <li>
                      <a href="#">Design (05)</a>
                    </li>
                    <li>
                      <a href="#">Branding (05)</a>
                    </li>
                    <li>
                      <a href="#">Playing (05)</a>
                    </li>
                    <li>
                      <a href="#">Browsing (05)</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*       blog_area     */}
  <Footer2/>
  </>
 
  )
}

export default Blog