import React from 'react'

const Footer = () => {
  return (
    <>
      {/*        section_five      */}
      <section className="section fp-auto-height w_footer_area">
        <div className="inro">
          <div className="container custom_container">
            <div className="footer_text">
              <h3>Subscirbe Newsletter</h3>
              <form action="#" className="subscribe_info">
                <input
                  type="text"
                  name="EMAIL"
                  className="form-control memail"
                  placeholder="Your email"
                />
                <button className="btn" type="submit">
                  <i className="arrow_right" />
                </button>
              </form>
              <ul className="list-unstyled social_link">
                <li>
                  <a href="#">
                    <i className="social_dribbble" />
                    <i className="social_dribbble" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="social_googleplus" />
                    <i className="social_googleplus" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="social_facebook" />
                    <i className="social_facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="social_twitter" />
                    <i className="social_twitter" />
                  </a>
                </li>
              </ul>
              <p>
                Copyright © 2020 <a href="#">DroitThemes</a> | All rights reserved
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*        section_five      */}
      {/*        full_footer      */}
      <footer className="full_footer p_absoulte f_footer_one">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-6">
              <ul className="list-unstyled social_icon">
                <li>
                  <a href="#">
                    <i className="social_twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="social_facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="social_googleplus" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-6">
              <div className="pr_details_nav h_slider_nav align-items-end">
                <span className="prev" id="moveUp">
                  Prev
                </span>
                <span className="next moveUp" id="moveDown">
                  Next
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*        full_footer      */}
    </>

  )
}

export default Footer