import React from 'react'
import Navbar2 from './Navbar2'
import Footer2 from './Footer2'

const ContactUs = () => {
  return (
    <>
    <Navbar2/>

    {/*        breadcrumb_area_five     */}
    <section
      className="breadcrumb_area_two breadcrumb_area_five parallaxie"
      data-background="img/bg.jpg"
      style={{ background: "url(assets/img/case_one/cases_banner.jpg) no-repeat" }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="breadcrumb_content">
          <h1>Contact Us</h1>
          <ol className="nav">
            <li>
              <a href="#">Home</a>
            </li>
            <li>contact us</li>
          </ol>
        </div>
      </div>
    </section>
    {/*        breadcrumb_area_five     */}
    {/*        contact_area     */}
    <section className="contact_area">
      <div className="container">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27509.55133282281!2d77.74145275828936!3d30.473106569265482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f2fb901fe8a51%3A0x1fa531562e47f5fe!2sVikasnagar%2C%20Uttarakhand!5e0!3m2!1sen!2sin!4v1713617284060!5m2!1sen!2sin" width="1100" height="450" style={{ border: 0 }}allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        <div className="row contact_info">
          <div className="col-lg-3 col-6">
            <div className="contact_info_item">
              <img src="assets/img/marker.png" alt="" />
              <h4 className="pr_title">Location</h4>
              <p>
                915 Hilldale Lane
                <br /> Maryville, TN 37803
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="contact_info_item">
              <img src="assets/img/phon.png" alt="" />
              <h4 className="pr_title">Contact Number</h4>
              <a href="#">+91 386-454-0624</a>
              <a href="#">+91 386-433-9096</a>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="contact_info_item">
              <img src="assets/img/email.png" alt="" />
              <h4 className="pr_title">Mail Addres</h4>
              <a href="#">abcd@example.com</a>
              <a href="#">mnop@domain.com</a>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="contact_info_item">
              <img src="assets/img/social.png" alt="" />
              <h4 className="pr_title">Social Profiles</h4>
              <ul className="list-unstyled social_link social_link_two">
                <li>
                  <a href="#">
                    <i className="social_dribbble" />
                    <i className="social_dribbble" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="social_googleplus" />
                    <i className="social_googleplus" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="social_facebook" />
                    <i className="social_facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="social_twitter" />
                    <i className="social_twitter" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row contact_inner">
          <div className="col-lg-7">
            <div className="w_content_two w_contact_text">
              <h2>Let's talk?</h2>
              <p>
                It's all about the humans behind a brand and those experiencing
                it, we're right there. In the middle.
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <form
              action="https://html.droitlab.com/wavee/contact_process.php"
              className="contact_form_box"
              method="post"
              id="contactForm"
              noValidate="novalidate"
            >
              <div className="form-group text_box">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Your Name"
                />
              </div>
              <div className="form-group text_box">
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                />
              </div>
              <div className="form-group text_box">
                <textarea
                  name="message"
                  id="message"
                  cols={30}
                  rows={10}
                  placeholder="Enter Your Message . . ."
                  defaultValue={""}
                />
              </div>
              <button type="submit" className="g_hover">
                Submit Now
              </button>
            </form>
            <div id="success">Your message succesfully sent!</div>
            <div id="error">Opps! There is something wrong. Please try again</div>
          </div>
        </div>
      </div>
    </section>
    {/*        contact_area     */}
     <Footer2/>
  </>
  
  )
}

export default ContactUs